/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages
            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function() {
                // JavaScript to be fired on the home page
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function() {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.


// Add html class to open mobile navigation
jQuery(function($) {
    $(".open-panel, .close-panel").on("click",function(e) {
        $("html").toggleClass("openNav");
    });
});

jQuery(function($) {
    $(".wrapper-masthead").headroom({
        offset : 85,
        "classes": {
            "top": "nav-top",
            "notTop": "nav-not-top",
            "pinned": "nav-down",
            "unpinned": "nav-up"
        }
    });
});

jQuery(function($) {
    $('.slick-one').slick
    ({
        autoplay: true,
        dots: true,
        fade: true,
        infinite: true,
        speed: 600,
        autoplaySpeed: 3000,
        slidesToShow: 1
    });
    $('.slick-one-no-dots').slick
    ({
        autoplay: true,
        dots: false,
        fade: true,
        infinite: true,
        speed: 600,
        autoplaySpeed: 3000,
        slidesToShow: 1
    });
    $('.slick-people').slick
    ({
        autoplay: true,
        dots: true,
        fade: true,
        infinite: true,
        speed: 600,
        autoplaySpeed: 5000,
        slidesToShow: 1
    });
    $('.slick-people-2').slick
    ({
        autoplay: true,
        dots: true,
        fade: true,
        infinite: true,
        speed: 600,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        rows: 2
    });
    $('.slick-people-3').slick
    ({
        autoplay: true,
        dots: true,
        fade: true,
        infinite: true,
        speed: 600,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        rows: 3
    });
    $('.slick-splash').slick
    ({
        autoplay: true,
        dots: true,
        fade: false,
        infinite: true,
        speed: 600,
        autoplaySpeed: 5000,
        slidesToShow: 1
    });
    $('.slick-sponsors').slick
    ({
        autoplay: true,
        dots: true,
        fade: true,
        infinite: true,
        speed: 600,
        autoplaySpeed: 3000,
        slidesToShow: 1
    });
    $('.slick-event-splash').slick
    ({
        arrows: false,
        autoplay: true,
        dots: false,
        fade: true,
        infinite: true,
        speed: 600,
        autoplaySpeed: 6000,
        slidesToShow: 1
    });
    $('.slick-testimonials').slick
    ({
        autoplay: true,
        dots: true,
        fade: true,
        infinite: true,
        speed: 600,
        autoplaySpeed: 10000,
        slidesToShow: 1
    });
    $('.slick-headlines').slick
    ({
        autoplay: true,
        dots: true,
        fade: true,
        infinite: true,
        speed: 600,
        autoplaySpeed: 3000,
        slidesToShow: 1
    });
    $('.slick-content-boxes').slick
    ({
        autoplay: false,
        dots: true,
        fade: true,
        infinite: true,
        speed: 600,
        slidesToShow: 1,
        responsive: [
            {
                breakpoint: 960,
                settings: "unslick"
            }
        ]
    });
});

jQuery(function($) {
    $('[data-toggle="popover"]').popover
    ({
        trigger: "hover"
    });
});

// search autofocus

jQuery(function($) {
    $('.controls .search > a').on('click', function (e) {
        $('.controls .search > a').toggleClass("search-show");
        $('.controls .search > div').toggleClass("search-show");
        setTimeout(function(){
            $('.controls .search-field').focus();
            $('.controls .search-field').attr('autofocus','autofocus');
        }, 100);
        e.preventDefault();
    });
    setTimeout(function(){
        $('.search.archive .search-field').focus();
        $('.search.archive .search-field').attr('autofocus','autofocus');
    }, 100);
});

// remove width and height from video iframe

jQuery(function($) {
    $('.video iframe').removeAttr('width');
    $('.video iframe').removeAttr('height');
});

// remove width and height from video iframe

jQuery(function($) {
    $('[data-toggle="popover"]').popover
    ({
        trigger: "hover"
    });
});

jQuery(function($) {
    $('.dropdown-toggle').dropdown();
});

jQuery(function($) {
    var length = $('.extras').children('div').length;
    $('.extras').addClass("count_" + length);
});

jQuery(function($) {
    $('.dmg-module.tight-top').prev('.dmg-module').addClass('remove-box-shadow');
});

// Google Maps Rendering

(function($) {

    /*
     *  new_map
     *
     *  This function will render a Google Map onto the selected jQuery element
     *
     *  @type  function
     *  @date  8/11/2013
     *  @since 4.3.0
     *
     *  @param $el (jQuery element)
     *  @return  n/a
     */

    function new_map( $el ) {

        // var
        var $markers = $el.find('.marker');


        // vars
        var args = {
            zoom    : 16,
            center    : new google.maps.LatLng(0, 0),
            mapTypeId : google.maps.MapTypeId.ROADMAP
        };


        // create map
        var map = new google.maps.Map( $el[0], args);


        // add a markers reference
        map.markers = [];


        // add markers
        $markers.each(function(){

            add_marker( $(this), map );

        });


        // center map
        center_map( map );


        // return
        return map;

    }

    /*
     *  add_marker
     *
     *  This function will add a marker to the selected Google Map
     *
     *  @type  function
     *  @date  8/11/2013
     *  @since 4.3.0
     *
     *  @param $marker (jQuery element)
     *  @param map (Google Map object)
     *  @return  n/a
     */

    function add_marker( $marker, map ) {

        // var
        var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

        // create marker
        var marker = new google.maps.Marker({
            position  : latlng,
            map     : map
        });

        // add to array
        map.markers.push( marker );

        // if marker contains HTML, add it to an infoWindow
        if( $marker.html() )
        {
            // create info window
            var infowindow = new google.maps.InfoWindow({
                content   : $marker.html()
            });

            // show info window when marker is clicked
            google.maps.event.addListener(marker, 'click', function() {

                infowindow.open( map, marker );

            });
        }

    }

    /*
     *  center_map
     *
     *  This function will center the map, showing all markers attached to this map
     *
     *  @type  function
     *  @date  8/11/2013
     *  @since 4.3.0
     *
     *  @param map (Google Map object)
     *  @return  n/a
     */

    function center_map( map ) {

        // vars
        var bounds = new google.maps.LatLngBounds();

        // loop through all markers and create bounds
        $.each( map.markers, function( i, marker ){

            var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

            bounds.extend( latlng );

        });

        // only 1 marker?
        if( map.markers.length == 1 )
        {
            // set center of map
            map.setCenter( bounds.getCenter() );
            map.setZoom( 16 );
        }
        else
        {
            // fit to bounds
            map.fitBounds( bounds );
        }

    }

    /*
     *  document ready
     *
     *  This function will render each map when the document is ready (page has loaded)
     *
     *  @type  function
     *  @date  8/11/2013
     *  @since 5.0.0
     *
     *  @param n/a
     *  @return  n/a
     */
// global var
    var map = null;

    $(document).ready(function(){

        $('.map').each(function(){

            // create map
            map = new_map( $(this) );

        });

    });

})(jQuery);



/**
 * Tilt.js
 * @type type
 */


jQuery(document).ready(function(){
    jQuery('.js-tilt').tilt({
        maxTilt:        0,
        perspective:    1000,   // Transform perspective, the lower the more extreme the tilt gets.
        easing:         "cubic-bezier(.03,.98,.52,.99)",    // Easing on enter/exit.
        scale:          1,      // 2 = 200%, 1.5 = 150%, etc..
        speed:          600,    // Speed of the enter/exit transition.
        transition:     true,   // Set a transition on enter/exit.
        axis:           'x',   // What axis should be disabled. Can be X or Y.
        reset:          true,   // If the tilt effect has to be reset on exit.
        glare:          true,  // Enables glare effect
        maxGlare:       .5       // From 0 - 1.
    });

});


/*jQuery(document).ready(function(){
    jQuery('.headline-object h1').tilt({
        maxTilt:        40,
        perspective:    2000,   // Transform perspective, the lower the more extreme the tilt gets.
        easing:         "cubic-bezier(.03,.98,.52,.99)",    // Easing on enter/exit.
        scale:          1,      // 2 = 200%, 1.5 = 150%, etc..
        speed:          600,    // Speed of the enter/exit transition.
        transition:     true,   // Set a transition on enter/exit.
        //axis:           'x',   // What axis should be disabled. Can be X or Y.
        reset:          true,   // If the tilt effect has to be reset on exit.
        glare:          false,  // Enables glare effect
        maxGlare:       .5       // From 0 - 1.
    });

});*/


